import React from 'react'
import { FaYoutube } from 'react-icons/fa';

const Timetable = () => (
  <section id="tg-timetable" className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="harmonogram">
        <div className="tg-sectionheading">
          <h2>Harmonogram</h2>
        </div>
        <div className="harmonogram__grid">
          
          <ul className="harmonogram__week">
            <li className="harmonogram__days">
              Poniedziałek
              <div>6 września 2021</div>
            </li>
            <li className="harmonogram__days">
              Wtorek
              <div>7 września 2021</div>
            </li>
            <li className="harmonogram__days">
              Środa
              <div>8 września 2021</div>
            </li>
            <li className="harmonogram__days">
              Czwartek
              <div>9 września 2021</div>
            </li>
            <li className="harmonogram__days">
              Piątek
              <div>10 września 2021</div>
            </li>
            <li className="harmonogram__days">
              Sobota
              <div>11 września 2021</div>
            </li>
            <li className="harmonogram__days">
              Niedziela
              <div>12 września 2021</div>
            </li>
          </ul>
            <li className="harmonogram__hours">
            </li>
            <li className="harmonogram__hours">
              9:00
            </li>
            <li className="harmonogram__hours">
              10:00
            </li>
            <li className="harmonogram__hours">
              11:00
            </li>
            <li className="harmonogram__hours">
              12:00
            </li>
            <li className="harmonogram__hours">
              13:00
            </li>
            <li className="harmonogram__hours">
              14:00
            </li>
            <li className="harmonogram__hours">
              15:00
            </li>
            <li className="harmonogram__hours">
              16:00
            </li>
            <li className="harmonogram__hours">
              17:00
            </li>
            <li className="harmonogram__hours">
              18:00
            </li>
            <li className="harmonogram__hours">
              19:00
            </li>
            <li className="harmonogram__hours">
              20:00
            </li>
            <li className="harmonogram__hours">
              21:00
            </li>
            <li className="harmonogram__hours">
            </li>
            <div className="placeholder-grid"></div>
            <div className="grid__event -opening">
              Uroczyste otwarcie w bielskim Ratuszu
              <img className="bb__image" src="images/BB.png" />
            </div>
            <a className="grid__event -schools" href="https://app.evenea.pl/event/sasiedzisasiadom2021/">
                Kongres Sąsiedzi - Sąsiadom
                <img className="kongres__image" src="images/kongres_logo_white.png" />
            </a>
            <a className="grid__event -ath" href="#ath-start">
                Konferencja
                <img className="ath__image" src="images/ath.png" />
            </a>
            <a className="grid__event -bbq4it" href="https://bbq4.it/">
              <img className="bbq__image" src="images/bbq4it.png"></img>
              <img className="record-cs__image" src="images/rekord-cs.png" />
            </a>
            <span className="grid__event -hackaton --first">
                Hackathon
                <img className="hackaton__image" src="images/InduCCI-black.jpg" />
            </span>
            <span className="grid__event -hackaton --second">
                Hackathon
                <img className="hackaton__image" src="images/InduCCI-black.jpg" />
            </span>
            <a className="grid__event -BBdaysTV --first" href="https://www.youtube.com/watch?v=tlI_acArbv8" target="_blank" rel="noopener noreferrer">Studio BBDaysTV <FaYoutube className="yt-icon"/></a>
            <a className="grid__event -BBdaysTV --second" href="https://www.youtube.com/watch?v=v50-8bxPrXc" target="_blank" rel="noopener noreferrer">Studio BBDaysTV <FaYoutube className="yt-icon"/></a>
            <a className="grid__event -BBdaysTV --third" href="https://www.youtube.com/watch?v=okEOUcPx0aE" target="_blank" rel="noopener noreferrer">Studio BBDaysTV <FaYoutube className="yt-icon"/></a>
            <a className="grid__event -BBdaysTV --fourth" href="https://www.youtube.com/watch?v=Pu61w7SXXbI" target="_blank" rel="noopener noreferrer">Studio BBDaysTV <FaYoutube className="yt-icon"/></a>
            <a className="grid__event -meetup" href="https://www.meetup.com/pl-PL/meet-js-Bielsko-Biala/" target="_blank" rel="noopener noreferrer"/>
            <a className="grid__event -startup" href="https://www.startuppodbeskidzie.pl/"  target="_blank" rel="noopener noreferrer"/>
            <a className="grid__event -jugbb" href="https://www.meetup.com/pl-PL/Bielsko-Biala-JUG/"  target="_blank" rel="noopener noreferrer"/>
            <a className="grid__event -codebb" href="http://codebb.pl/"  target="_blank" rel="noopener noreferrer"/>

            {[...Array(97)].map((x, i) =>
            <div className="placeholder-grid"/>
          )}
            </div>
      </div>
    </div>
  </section>
)

export default Timetable
