import React from "react"

const Sponsors = () => (
  <>
    <section id="tg-organisators" className="tg-sectionspace tg-haslayout">
      <div className="container">
        <div className="row">
          <div
            className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
            <div className="tg-sectionhead">
              <div className="tg-sectionheading">
                <h2>Organizatorzy</h2>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ul className="tg-brands">
              <li className="tg-brand">
                <figure><a href="http://www.rekord.com.pl" target="_blank" rel="noopener noreferrer"><img
                  src="images/sponsorzy/rekord.png" alt="Rekord"/></a></figure>
              </li>
              <li className="tg-brand">
                <figure><a href="https://selleo.com/" target="_blank" rel="noopener noreferrer"><img
                  src="images/partnerzy/selleo.png" alt="Selleo"/></a></figure>
              </li>
              <li className="tg-brand">
                <figure><a href="https://www.precisely.com/" target="_blank" rel="noopener noreferrer"><img
                  src="images/sponsorzy/precisely.png" alt="Precisely"/></a></figure>
              </li>
              <li className="tg-brand">
                <figure><a href="https://www.theice.com/index" target="_blank" rel="noopener noreferrer"><img
                  src="images/sponsorzy/ice.png" alt="ICE"/></a></figure>
              </li>
              <li className="tg-brand">
                <figure><a href="http://www.fcnt.eu/kontakt.html" target="_blank" rel="noopener noreferrer"><img
                  src="images/sponsorzy/fcnt.png" alt="FCNT"/></a></figure>
              </li>
              <li className="tg-brand">
                <figure><a href="https://arrsa.pl/pl" target="_blank" rel="noopener noreferrer"><img
                  src="images/partnerzy/arsabb.png" alt="Arrsa"/></a></figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default Sponsors
