import React from "react"
import Sponsors from "./Sponsors"
import MediaPatrons from "./MediaPatrons"
import SchoolPatrons from "./SchoolPatrons"
import Counter from "./Counter"
import News from "./Blog"
import Partners from "./Partnerzy"
import Harmonogram from './Harmonogram'
import Agenda1 from './Agenda1'
import HonoraryPatrons from './HonoraryPatrons'
import WelcomeButtons from "../WelcomeButtons"
import BusinessPatrons from "./BusinessPatrons"
import Meetups from "./Meetups"

const Main = () => (
  <main id="tg-main" className="tg-main tg-haslayout">
    <WelcomeButtons />
    <News />
    <Harmonogram />
    <Agenda1 />
    <Counter />
    <Sponsors />
    <HonoraryPatrons />
    <Partners />
    <Meetups />
    <SchoolPatrons />
    <BusinessPatrons />
    <MediaPatrons />
  </main>
)

export default Main
