import React, {useState} from 'react'
import ReactModal from 'react-modal';

const prelegentsData = [
  {
    name: "Bartek Michalski",
    title: "Dlaczego wprowadzanie zmian jest tak cholernie trudne?",
    description: `Miał*ś idealną wizję. Już widział*ś się w butach Bezosa, Jobs czy Gatesa.
    Stwierdził*ś, że wystarczy tylko działać i jakoś to pójdzie ;)
    
    No i nie poszło.
    
    Wprowadzanie kompleksowych zmian może być nie lada wyzwaniem. Niezależnie od tego czy chcesz wprowadzić zmianę na poziomie organizacji zatrudniających 800 osób, stworzyć własnego bloga ze znajomymi czy stworzyć autorski system RPG.
    Możemy oczywiście liczyć na szczęście lub zrozumieć czemu to zjawisko jest tak trudne i czemu wymaga przygotowania.
    
    Podczas prezentacji pokażę Ci 6 aspektów, na które warto zwrócić uwagę by móc ocenić czy jesteśmy w ogóle gotowi by wprowadzić zmianę i ocenić nasze braki.`,
    about: `W dzień Big Data Architect | Lead Developer | Konsultant | Trener, 
    w nocy śpi.
   Ponad 11 lat doświadczenia komercyjnego w zakresie wytwarzania oprogramowania w różnych technologiach oraz domenach, również w takich, w których nikt nie chciał pracować. Ostatnio współtworzę rozwiązanie w chmurze GCP.
   Współtworzę takie miejsca jak devenv.pl (w tym kanał YouTube) oraz dostarczajwartosc.pl. 
   Prelegent oraz organizator licznych wydarzeń min: Wakacyjnych Praktyk w Future Processing, WUD Silesia, Cloudyna, Gliwice Software BarCamp.`
  },
  {
    name: "Przemek Smyrdek",
    title: "Product Engineering, czyli lekcje z Doliny Krzemowej",
    description: `Przemek opowie o tym, kim jest product engineer i jak jego rola w zespole różni się od roli standardowego programisty. 
    Będzie to także prelekcja o tym, jak pracują zespoły w dolinie krzemowej. Dowiemy się również jak pracować skutecznie i utrzymać swoje stanowisko pomimo
    postępującej automatyzacji wszystkiego, dlatego prelekcję polecamy specjalistom IT, którzy szukają inspiracji i chcąwejść na kolejny level swojej pracy.`,
    about: `Przemek dzieli się swoją wiedzą zarówno na Przeprogramowani.pl, jak i w ramach programów szkoleniowych takich jak Opanuj JavaScript oraz LevelUp.Pomaga programistom wzmacniać kompetencje techniczne i rozwijać umiejętności miękkie. Poprzez zdobywanie doświadczenia w zawodzie programisty, ale też bycie liderem zespołu i sesje mentoringowe miał okazję poznać wyzwania programistów z rozmaitych organizacji i domen biznesowych.`
  },
  {
    name: "Dawid Jarco",
    title: "Strategia Biznesowa w branży IT (SaaS)",
    description: `Niezależnie od tego, czy spółka IT skupia się na innowacyjnym produkcie, czy kompleksowych rozwiązaniach: w długim okresie konieczne jest zapewnienie zysków oraz zrównoważonego wzrostu sprzedaży. Nie tylko po to, aby opłacać pracowników oraz tworzyć nowe produkty, lecz aby nie zostać wypartym przez konkurencję. Te i inne kwestie są istotą całościowej
    strategii biznesowej. Szeroko pojęty SaaS (Software as a Service) jest jednym z najbardziej nowoczesnych oraz popularnych modeli biznesowych wśród branży IT. Jego popularność w ostatnich latach można szczególnie zaobserwować na przykładzie firm z USA. Giganty takie jak Amazon,
    Microsoft, Salesforce czy nawet Google przechodzą z tradycyjnego modelu biznesowego nastawionego na licencję właśnie w kierunku SaaS, który może zagwarantować zrównoważony rozwój.
    Dawid opowie więc o tym, że firmy IT skupiają się nie tylko na programowaniu, lecz także na planowaniu każdego kroku swoich działań z myślą o swojej egzystencji finansowej`,
    
    about: `Analityk biznesowy z zawodu, ekonomista z zamiłowania. Większą część życia spędził w Austrii, gdzie zdobywał edukację oraz doświadczenie jako finansista. Pracował w Wiedniu w globalnych spółkach akcyjnych, m. in. KPMG, Rewe AG czy Mondi Plc, gdzie był odpowiedzialny za wyniki finansowe w europie środkowo-wschodniej. Jego zawodowa przygoda z sektorem IT rozpoczęła się w roku 2019 w firmie Precisely. Obecnie Dawid jest odpowiedzialny za zarządzanie finansowo-operacyjne oraz współuczestniczy w tworzeniu strategii biznesowej całej jednostki CPO (Chief Product Officer). W 2012 został absolwentem Vienna Business School, z kolei w 2017 studiów magisterskich o kierunku eknomia managerska na Uniwersytecie Ekonomicznym w Krakowie. Skutkowało to powrotem do Polski oraz podjęciem pracy w kraju, jak i dalszym rozwojem naukowym. Studia doktoranckie w j. angielskim ukończył w 2020 roku, obecnie jest na etapie końcowym swoich badań dotyczących modelowania wzrostu PKB uwzględniając postęp technologiczny. Dawid jest także autorem trzech artykułów naukowych dotyczących m. in. problematyki państw biedniejszych doganiających kraje bogatsze.`
  },
  {
    name: "Michał Wójtowicz",
    title: "Nie ogarniesz security skończoną liczbą studentów",
    description: `Aplikacje w chmurze są bardziej narażone na ataki niż
    kiedykolwiek.
    
    Przyjdź dowiedzieć się, jak uchronić się przed hakerami,
    którzy "po wódeczce" mogliby chcieć poszperać w twoim kodzie.`,
    about: `Bujam w obłokach AWS od kilku ładnych lat. Obecnie buduję
    soft w Siemens dla branży EDA. Hobbystycznie szkolę cloud developerów.`
  },
  {
    name: "Mateusz Heinrich",
    title: "IT Security z perspektywy RODO",
    description: `Tak wiele mówi się o RODO i nadal tak wiele nie wiemy… a przecież może nas sporo kosztować.

    Właśnie dlatego warto posłuchać o naruszenia ochrony danych osobowych w praktyce firmowej. 
    Wspólnie dokonać przeglądu kar Urzędu Ochrony Danych Osobowych i dowiedzieć się co z nich wynika w praktyce dla przedsiębiorców niezależnie od rozmiarów i branży.
    Usłyszymy także jakie środki bezpieczeństwa wdrażać w firmie, aby uniknąć naruszeń.
    Zagadnienia prawne i technologiczne zostaną prezentowane w wymiarze praktycznym.
    Podczas prezentacji zostaną przybliżone także założenia do prawidłowej realizacji obowiązków RODO w firmach sektora IT.
     
    Będzie mowa o tym: 
    - jak mapować procesy przetwarzania, 
    - jak prowadzić czytelne rejestry czynności,
    - czy warto wyznaczyć IOD’ę w organizacji.
    To spotkanie polecamy każdemu kto chce wbudować ochronę danych w swój biznes i osiągnąć przy tym przewagę konkurencyjną a jednocześnie… nie utonąć w administracji.”`,
    about: `Mateusz Heinrich
    Radca prawny, partner zarządzający w kancelarii JuvoNa co dzień doradza firmom w zakresie ochrony danych osobowych w szczególności w podmiotach działających w ramach międzynarodowych grup kapitałowych oraz w obszarze nowych technologii. Doświadczony trener i Inspektor Ochrony Danych lubiący udowadniać, że o ochronie danych osobowych można mówić w sposób przystępny.`
  },
  {
    name: "Przemysław Hojnacki",
    title: "Chatbot - interfejs konwersacyjny",
    description: `Czy porozumiewałeś się kiedyś z maszyną w swoim naturalnym, ludzkim języku?
    Wirtualni asystenci zastępują ludzi tam gdzie komunikacja jest schematyczna ale coraz częściej spotykamy wirtualnych asystentów bazujących na sztucznej inteligencji, którzy rozumieją także złożone pytania i potrafią dobrze zrozumieć intencję użytkownika. Tacy asystenci poprzez proces uczenia potrafią
    reagować coraz dokładniej na wydawane polecenia. Być może zastanawiałeś się jak to się dzieje, że maszyna rozumie zdania wypowiadane w języku naturalnym?
    Jeśli chcesz posłuchać o wirtualnych asystentach w systemach klasy ERP czy też asystentach w bankowości ale i o rozwiązaniach które znajdują zastosowanie w naszym najbliższym otoczeniu (jak dom) to ta prelekcja jest dla Ciebie.`,
    about: 'Principal Software Engineer w firmie Pitney Bowes, programista z ponad szesnastoletnim doświadczeniem. Specjalizuje się w technologiach Microsoftowych związanych z mikroserwisami. Zdobywał doświadczenie z asystentami wirtualnymi zarówno w kraju jak i w Wielkiej Brytanii. Prywatnie miłośnik morsowania.'
  },
  {
    name: "Dawid Holisz",
    title: "Prawy do lewego czyli aplikacje czytane od prawej do lewej",
    description: `W krajach w których teksty są czytane od prawej do lewej, nie tylko kierunek czytania jest inny. Z punktu widzenia frontend developera, uwagę musimy zwrócić również na wszystkie inne rzeczy w których kierunek ma znaczenie, zaczyniwszy od strzałek wstecz/dalej aż do progressbarów. Na mojej prezentacji przedstawię kilka przykładów i problemów z jakimi można się spotkać pisząc aplikacje na rynek np. Arabski oraz sposoby jak można je rozwiązać`,
    about: `Dawid Holisz, doświadczony frontend developer w Selleo, na co dzień pracujący w React. Do jego ulubionych zajęć należy optymalizacja aplikacji mobilnych oraz pisanie CSS. W wolnym czasie chętnie doszkala się z obszaru rozwoju technologii, poszukując nowinek w obszarze IT i inspirując się do nowatorskich rozwiązań na rzecz kolejnych projektów.`
  },
  {
    name: "Adrian Kapczyński",
    title: "Megatrendy informatyki",
    description: `Zapraszam
    do wysłuchania informacji o nowościach w obszarze megatrendów 
   informatyki: AI, IoT, XR, Cloud Computing oraz Cybersecurity.`,
    about: `Uczeń  Profesora Andrzeja Grzywaka, pasjonat informatyki, od ponad 23 lat 
    zgłębiający zagadnienia związane z informatyką oraz jej zastosowaniami. 
    Wykładowca akademicki, społecznik, kierownik projektów, konsultant, 
    szkoleniowiec i audytor.  Członek zarządu Sekcji Cyberbezpieczeństwa 
    PTI, prezes zarządu Sekcji Przyszłości IT PTI oraz dyrektor regionalny 
    Izby Rzeczoznawców PTI`
  },
  {
    name: "Beata Lipska",
    title: "Elastyczność a wypalenie zawodowe w zespołach developerskich",
    description: `Jest tylko jedna stała rzecz w życiu - obecność zmian. Tak samo jest w pracy w branży IT - jedyne, czego możemy się spodziewać to zmiany, ponieważ jesteśmy jej narzędziami - tworząc i ustawicznie udoskonalając technologię, nad którymi pracujemy. Jednakże istnieją też zmiany, na które nie mamy wpływu, które niekoniecznie mogą w pierwszym momencie służyć nam i naszemu wzrostowi. Jak sobie z tym radzić? Jak zachować siebie od wypalenia zawodowego i jak wpływać na swój team? Na te i inne pytania znajdziesz odpowiedź podczas mojej prezentacji. Zapraszam!`,
    about: `PHP/Go👩🏻‍💻
    Porgramistka na Backendzie 😇Soft Skills Preacher
     📝Bloggerka 👩🏻‍🏫
    Edukatorka ✏️Ilustratorka  ✍️Publicystka 🎙🗣Public
    Speakerka  👩‍👩‍👦Rodzic
    🏳️‍🌈🇵🇱🇬🇧🏴󠁧󠁢󠁥󠁮󠁧󠁿🇪🇺
    Ona/Jej
    Pracuje w londyńskim fin-tech’u Padle.com, gdzie rozwija się jako programistka, a także głoszi rozważania o umiejętnościach miękkich, ponieważ ludzie fascynują Ją równie mocno jak kod!`
  },
  {
    name: "Daniel Dec",
    title: "Aby z jakości nie zostały same ości",
    description: `Jakość to nie tylko testy i czysty kod. Jakość to wypadkowa
    wielu czynników prowadzenia projektu oraz developmentu. Podczas prezentacji
    chcę się z Tobą
    
    podzielić swoimi obserwacjami i doświadczeniem na temat antywzorców i słabych
    punktów, które wpływają na jakość projektu IT. Będą to zarówno aspekty
    
    techniczne takie jak architektura, testowalność czy sposoby testowania na
    różnych poziomach, jak i aspekty miękkie dotyczące komunikacji i kultury pracy
    w organizacji.
    
    Przekażę Ci esencję konsekwencji braku stosowania dobrych praktyk (tych
    oczywistych i tych mniej oczywistych) lub błędnego ich stosowania, które
    wpływają na zaciąganie długu technicznego i 
    
    prowadzą do obniżenia poziomu jakości dostarczanych rozwiązań. Porozbijam kilka
    mitów na temat jakości i przedstawię alternatywne sposoby radzenia sobie z
    jakością.
    
    Byłem tam - w wielu miejscach gdzie zmiana jest trudna lub pozornie niemożliwa.
    Zmieniałem proces i podejście - wiem, że to możliwe i wiem jak to robić.
    
    Nie ważne czy pochodzisz z ciężkiego enterprise, budujesz statek kosmiczny czy
    samochód, albo piszesz aplikację CRUDową - myślę, że wyciągniesz coś dla siebie
    z moich doświadczeń.`,
    about: `Pracował w wielu organizacjach, gdzie spełniał się w
    różnych rolach związanych z wytwarzaniem oprogramowania, co wykształciło w nim
    holistyczne podejście
    
    do projektów. Jego głównym celem i motywacją
    od zawsze było, aby pozostawić organizację, z którą współpracuje w lepszym
    stanie niż gdy do niej przyszedł - czemu to
    
    oddaje się z pasją od lat. Tworzył zespoły i
    zarządzał nimi, projektował procesy i dbał o wdrażanie dobrych praktyk.
    Wszystko to dało
    
    mu szansę poznać prowadzenie projektu z
    różnych perspektyw, odkryć słabe punkty wszystkich z wymienionych obszarów
    i wypracować dla nich najbardziej
    
    efektywne rozwiązania. Dlatego kilka lat temu
    wkroczył na ścieżkę konsultingu. Wie jak łamać status quo i
    zapoczątkowywać transformacje. Umie automatyzować i przyspieszać wzrost
    
    w frmach z branży IT, identyfkować wąskie
    gardła i zmieniać sposób myślenia o transformacji tak, aby w efekcie
    ułatwić zmianę na lepsze. Stara się być technology-agnostic, mieć szeroką
    wiedzę na temat wielu
    
    technologii, nie przywiązując się do żadnej
    konkretnej.  Poza pracą oddaje się często analogowym i cyfrowym falom
    dźwiękowym, tworząc samemu jak i miksując za konsolą tłuste bity. Próbuje
    nadganiać ranking szachowy. Spotkać go też można na boisku.`
  },
  {
    name: "Daniel Kawka",
    title: "Dbanie o zdrowie kręgosłupa mając pracę siedzącą jest proste. Bardzo proste.",
    description: `Tak błahy problem jakim jest ból pleców urósł w ostatnich latach do ogromnego problemu. Jest to
    najczęstsza przyczyna niezdolności do pracy na świecie. Wiąże się to w dużej
    mierze ze zmianą stylu życia ludzi, w tym z coraz częstszym przejściem do pracy
    siedzącej.Zapobieganie temu problemowi nie jest jednak wcale takie trudne. Wystarczy kierować się
    kilkoma, bardzo prostymi wskazówkami, o których opowie nam Daniel.`,
    about: `Daniel Kawka -  jest fizjoterapeutą, którego zainteresowania zawodowe związane są z zapobieganiem i terapią przewlekłego bólu. Od samego początku kariery zawodowej edukuje pacjentów oraz terapeutów w temacie pracy z bólem. Był prelegentem na licznych szkoleniach i konferencjach, w tym między innymi na międzynarodowej konferencji TEDx.
   Cenne doświadczenie zdobył również zakładając i prowadząc Fundację Kuloodporni, której celem jest ktywizacja sportowa osób niepełnosprawnych.`
  },
  {
    name: "Maciek Korsan",
    title: "Hiperdivoza i inne choroby Twojego HTML-a ",
    description: `HTML jest jednym z pierwszych języków, które poznajesz w trakcie nauki webdevelopmentu. Używają go wszyscy niezależnie od tego czy tworzą statyczne strony internetowe, czy złożone SPA. Niestety jest on podatny na różne choroby, które wpływają na to jak Twoja strona/aplikacja jest odbierana. W trakcie prezentacji zobaczysz różne ciekawe przypadki oraz dostaniesz receptę do szybkiej realizacji w Twoim projekcie.`,
    about: `Frontend developer z głową pełną pomysłów i ponad 15 letnim doświadczeniem w tworzeniu nieszablonowych rozwiązań internetowych. Lubi mieszać sztukę z kodem. Albo kod ze sztuką - jeszcze nie zdecydował. Kocha CSS i fotografię. Właściciel Sokoła Millennium z Lego.`
  },
  {
    name: "Marek Pagacz",
    title: "Why UX matters",
    description: "Mimo rosnącej roli UX designerów w firmach IT, nadal nie wszystkie posiadają w swoich zespołach takich zawodników lub nie do końca są przekonane o konieczności ich posiadania. Współczesny poziom konkurencyjności i przyjazności dla użytkownika jest tak wysoki, iż firmy IT nie inwestujące w UX zostają coraz bardziej w tyle. Podczas mojej prezentacji, przedstawię wam jak w praktyce wygląda praca UX'a i jak ogromny wpływ na sukces soft'u ma UX designer.",
    about: `UX Interaction Designer w Precisely (wcześniej Pitney Bowes). Projektuję UX/UI od kilku lat dla cloud'owych platform na poziomie E2E. Z racji na swoje rosnące doświadczenie, coraz mocniej wkraczam w arenę szkoleń, pragnąc przekazać młodszym pokoleniom czym jest idea UX i dlaczego jest to coraz bardziej istotna gałąź produkcji dobrego soft'u.`
  },
  {
    name: "Łukasz Gaweł",
    title: "Exploitowanie zamiast hackowania - czyli jak wygrać loterię, dofinansowanie lub jeść za darmo",
    description: `Temat bezpieczeństwa IT coraz częściej pojawia się w mediach między innymi poprzez liczne wycieki danych, ataki na polityków czy infekcje ransomware prowadzące do gigantycznych okupów. Jednak często zapomina się o tym, że podatności powstają nie tylko w kodzie aplikacji, a wiele z nich już na poziomie pomysłu lub projektu oraz braku świadomości jakie konsekwencje może nieść za sobą wykorzystanie funkcjonalności w inny sposób niż zaplanowali to pomysłodawcy. W tej prezentacji postaram się pokazać niepozornie wyglądające błędy logiki biznesowej, które zostały wykorzystane w spektakularny sposób i często prowadziły do strat wizerunkowych lub finansowych firm.`,
    about: `Łukasz od 10 lat pracuje jako developer. Zaczynał od desktopowych aplikacji, ale szybko przesiadł się na Web bo dostrzegł w nim duży potencjał. Przeszedł przez wiele technologii backendowych oraz frontendowych. Najbardziej jednak zawsze interesowało go bezpieczeństwo systemów, które cały czas jest zaniedbywane. Aktualnie zajmuje się szkoleniami oraz rozwijaniem własnych produktów do automatyzacji marketingu i zbierania danych, gdzie znajomość tematu bezpieczeństwa jest bardzo pomocna.`
  },
  {
    name: "Dawid Ostręga",
    title: "Recovering from a major leadership failure",
    description: `Coasting following a successful project delivery can, and do
    lead to major failures in subsequent projects.
    
    A real-life case study of a management failure post a major success. The
    analysis of the recovery process, and an honest retrospective of the changes
    made.`,
    about: `Dawid is a seasoned leader with over two decades of
    experience. With successes he built from ground up Polish branches of EPAM,
    SolarWinds and Vizent Digital.  Additionally lead major projects for
    Amazon, IBM,iLoop and Medical Marketing Studies Inc. on both sides of the
    Atlantic, and is the former CEO of SKK S.A.Outside of the workplace, Dawid is
    the Head Coach of the Kraków Kings, which he twice lead to the final of the
    PLFA1 (Polish League of American Football) national championship.  A
    scoutmaster, university lecturer and former international athlete. Thanks
    to a unique blend of  experiences in business, sports and the scouting
    movement, he developed his own vision of leadership and inspiration. Dawid’s
    talks are closely related to his real life experiences, asto help the audience
    relate to the topic at hand. `
  }
]

const Timetable = () => {
  const agendaData = [
    {
    classes: "harmonogram__days --agenda-left",
    text: "Sala pierwsza"
    },
    {
      classes: "harmonogram__days --agenda-right",
      text: "Sala druga"
    },
    {
      classes: "harmonogram__hours --agenda",
      text: "8:00"
    }, 
    {
      classes: "grid__event -ath-open",
      text: `Rejestracja (<a href="https://goo.gl/maps/YVRwUKcGbaeCnCW76" target="_blank">ATH Bielsko-Biała, ul. Willowa 2</a>, budynek L)`
  
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "9:00"
    }, 
    {
      classes: "grid__event -ath-welcome",
      text: "Powitanie"
    }, 
    {
      classes:"harmonogram__hours --agenda",
      text: "9:20"
    }, 
    {
      classes: "grid__event -ath-first grid__talk -clickable",
      text: "Bartłomiej Michalski - Dlaczego wprowadzanie zmian jest tak cholernie trudne?",
      onClick: () => handleOpenModal(0)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "10:00"
    }, 
    {
      classes: "grid__event -ath-break",
      text: "Przerwa kawowa"
    }, 
    {
      classes:"harmonogram__hours --agenda",
      text: "10:20"
    }, 
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Przemek Smyrdek - Product Engineering, czyli lekcje z Doliny Krzemowej",
      onClick: () => handleOpenModal(1)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Dawid Jarco - Strategia Biznesowa w branży IT (SaaS)",
      onClick: () => handleOpenModal(2)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "11:00"
    },
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Michał Wójtowicz - Security aplikacji w chmurze",
      onClick: () => handleOpenModal(3)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Mateusz Heinrich - IT Security z perspektywy RODO",
      onClick: () => handleOpenModal(4)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "11:40"
    },
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Przemysław Hojnacki  - Chatbot - interfejs konwersacyjny",
      onClick: () => handleOpenModal(5)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Dawid Holisz - Prawy do lewego czyli aplikacje czytane od prawej do lewej",
      onClick: () => handleOpenModal(6)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "12:10"
    }, 
    {
      classes: "grid__event -ath-second-break",
      text: "Obiad (Lighting talki)"
    }, 
    {
      classes:"harmonogram__hours --agenda",
      text: "13:00"
    },
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Adrian Kapczyński - Megatrendy informatyki",
      onClick: () => handleOpenModal(7)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Beata Lipska - Elastyczność a wypalenie zawodowe w zespołach developerskich",
      onClick: () => handleOpenModal(8)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "13:40"
    },
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Daniel Dec - Aby z jakości nie zostały same ości",
      onClick: () => handleOpenModal(9)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Daniel Kawka - Dbanie o zdrowie kręgosłupa mając pracę siedzącą jest proste. Bardzo proste.",
      onClick: () => handleOpenModal(10)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "14:20"
    }, 
    {
      classes: "grid__event -ath-break",
      text: "Przerwa kawowa"
    }, 
    {
      classes:"harmonogram__hours --agenda",
      text: "14:40"
    },
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Maciek Korsan - Hiperdivoza i inne choroby Twojego HTML-a ",
      onClick:() => handleOpenModal(11)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Marek Pagacz - Why UX matters",
      onClick: () => handleOpenModal(12)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "15:20"
    },
    {
      classes: "grid__event -ath-left grid__talk -clickable",
      text: "Łukasz Gaweł - Exploitowanie zamiast hackowania - czyli jak wygrać loterię, dofinansowanie lub jeść za darmo",
      onClick: () => handleOpenModal(13)
    },
    {
      classes: "grid__event -ath-right grid__talk -clickable",
      text: "Dawid Ostręga - Recovering from a major leadership failure",
      onClick: () => handleOpenModal(14)
    },
    {
      classes:"harmonogram__hours --agenda",
      text: "16:00"
    }, 
    {
      classes: "grid__event -ath-second-break",
      text: "Podsumowanie konferencji + rozpoczęcie hackathonu"
    }, 
    {
      classes:"harmonogram__hours --agenda",
      text: "17:00"
    }, 
  
  ]

  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleOpenModal = (dataId) => {
    const data = prelegentsData[dataId]
    setModalData(data)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setModalData()
    setShowModal(false)
  }

  return(
  <section id="ath-start" className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="harmonogram harmonogram__sobota">
        <div className="tg-sectionheading">
          <h4>Sobota - 11 Września - part hard</h4>
          <h2>Agenda </h2>
        </div>
        <div className="harmonogram__grid5">
          {agendaData.map( (event, index) => (<div key={index} className={event.classes} onClick={event.onClick} dangerouslySetInnerHTML={{__html:event.text}}/>))}
        </div>
      </div>
      <ReactModal
           isOpen={showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={handleCloseModal}
           shouldCloseOnOverlayClick={true}
           className="react-model__content"
           overlayClassName="react-model__overlay"
           preventScroll={true}
        >
        <button className="react-model__close-button" onClick={handleCloseModal}>&times;</button>
          <div class="panel panel-default">
            <div class="panel-heading">Prelegent</div>
            <div class="panel-body">{modalData?.name}</div>
            <div class="panel-heading">Tytuł prelekcji</div>
            <div class="panel-body">{modalData?.title}</div>
            <div class="panel-heading">Opis prelekcji</div>
            <div class="panel-body">{modalData?.description}</div>
            <div class="panel-heading">O prelegencie</div>
            <div class="panel-body" >{modalData?.about}</div>
        </div>
        </ReactModal>
    </div>
  </section>
)}

export default Timetable
