import React from 'react'

const SchoolPatrons = () => (
  <section className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Partnerzy Naukowi</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://www.ath.bielsko.pl/" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/ath.png" alt="Reset ATH" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.wsi.edu.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/wsiz.png" alt="Wyższa Szkoła Informatyki i Zarządzania w Bielsku-Białej" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://technikum.wsi.edu.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/pti.png" alt="Prywatne Technikum Informatyczne" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.ath.bielsko.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/reset.png" alt="Reset ATH" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://f44red.com/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/f44_logo.jpeg" alt="f44red" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default SchoolPatrons
