import React from 'react'

const HonoraryPatrons = () => (
  <section className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Partnerzy Honorowi</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://bielsko-biala.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/bb.png" alt="Urzad miasta" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.ath.bielsko.pl/" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/ath.png" alt="Reset ATH" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default HonoraryPatrons
