import React, { Component } from "react"
import { Link } from "gatsby"
import cn from "classnames"
export default class ExternalHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isToggled: false,
    }
  }

  componentDidMount() {
    const elements = document.querySelectorAll(".tg-navigation ul li a")
    elements.forEach(element =>
      element.addEventListener("click", () => this.toggleCollapse(false))
    )
    this.setState({ isBrowser: typeof window !== "undefined" })
  }

  componentWillUnmount() {
    const elements = document.querySelectorAll(".tg-navigation ul li a")
    elements.forEach(element =>
      element.removeEventListener("click", this.toggleCollapse)
    )
  }

  openBlank(url) {
    window.open(url, "_blank")
  }

  toggleCollapse = (isToggled = !this.state.isToggled) =>
    this.setState({ isToggled })

  render() {
    const { isToggled } = this.state
    const { onlyFixed } = this.props
    const isBrowser = typeof window !== "undefined"
    return (
      <header
        id="tg-header"
        className={cn(
          "tg-header tg-headervtwo",
          onlyFixed && "tg-header-fixed"
        )}
      >
        <div className="container-fluid">
          <div className="row m-auto" >
            <strong className="tg-logo">
              <Link to="/">
                <img
                  src="/images/bb_days_6-12_it_light_2021.svg"
                  alt="BB Days 4IT"
                />
              </Link>
            </strong>
            <div className="tg-navigationarea col-lg-9">
              <nav id="tg-nav" className="tg-nav">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    aria-expanded="false"
                    onClick={() => this.toggleCollapse()}
                  >
                    <span className="sr-only">Menu</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div
                  className={`collapse navbar-collapse tg-navigation ${
                    isToggled ? "in" : ""
                  }`}
                >
                  <ul>
                    <button
                      type="button"
                      className="navbar-toggle__mobile"
                      aria-expanded="false"
                      onClick={() => this.toggleCollapse(false)}
                    >
                      <i className="fa fa-times fa-2x"></i>
                      <span className="sr-only">Close Menu</span>
                    </button>
                    <li>
                      <Link
                        activeClass="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page",
                            'external'
                        )}
                        to="/#body"
                        rel="noopener noreferrer"
                        smooth
                        spy
                      >
                        Start
                      </Link>
                    </li>
                    <li>
                      <Link
                      activeClass="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page",
                        )}
                        to="/#tg-news"
                        rel="noopener noreferrer"
                        smooth
                        spy
                        offset={-50}
                      >
                        Aktualności
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page"
                        )}
                        to="/#tg-timetable"
                        rel="noopener noreferrer"
                        smooth
                        spy
                        offset={-50}
                      >
                        Harmonogram
                      </Link>
                    </li>
                    <li>
                      <Link
                       activeClass="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page"
                        )}
                        to="/#ath-start"
                        smooth
                        spy
                        offset={-50}
                      >
                        Konferencja ATH
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page"
                        )}
                        to="/#tg-organisators"
                        smooth
                        spy
                        offset={-50}
                      >
                        Organizatorzy
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page"
                        )}
                        to="/#tg-partners"
                        smooth
                        spy
                        offset={-50}
                      >
                        Partnerzy
                      </Link>
                    </li>
                    <li>
                      <Link
                       activeClassName="current"
                        className={cn(
                          isBrowser &&
                            window.location.pathname !== "/" &&
                            "cross-page",
                            'external'
                        )}
                        to="/gallery"
                        rel="noopener noreferrer"
                        spy
                      >
                        Galeria
                      </Link>
                      </li>
                    <li>
                      <a
                        className="external"
                        href="https://bbdays4it-2020.netlify.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Edycja 2020
                      </a>
                    </li>
                    <li>
                      <a
                        className="external"
                        href="https://2019.bbdays4.it"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Edycja 2019
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
