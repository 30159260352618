import React from 'react'

const Meetups = () => (
  <section className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Meetup'y</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://www.startuppodbeskidzie.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/sup.png" alt="Startup Podbeskidzie" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://codebb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_codebb.png" alt="CodeBB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.meetup.com/Bielsko-Biala-JUG/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_bbjug.png" alt="BB JUG" /></a></figure></li>
           <li className="tg-brand"><figure><a href="https://www.meetup.com/meet-js-Bielsko-Biala/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_meetjs_bb.png" alt="Meet.js BB" /></a></figure></li>
           <li className="tg-brand"><figure><a href="https://mikstura.it/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_mikstura.png" alt="Mikstura.IT" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default Meetups