import React, {useLayoutEffect} from "react"
import { Gallery } from "../../../plugins/gatsby-theme-gallery";

import Layout from '@components/Layout'
import SEO from '@components/Seo'
import ExternalHeader from '@components/externalHeader/ExternalHeader'
import Footer from '@components/footer/Footer'

const GalleryPage = ({data}) => {

  useLayoutEffect(()=> {
    document.getElementById('body').classList.add('tg-fixedme');
  }, [])

  return(
    <Layout>
      <SEO title="BB Days 4 IT :: 6 - 12 września 2021" />
      <ExternalHeader onlyFixed />
      <div className="tg-gallerymain">
        <div className="tg-containerholder">
          <div className="container gallery-container">
            <div className="tg-sectionhead tg-textalignleft">
              <div className="tg-sectionheading">
                <span>Great memories</span>
                <h2>Our gallery wall</h2>
              </div>
            </div>
            <div className="tg-gallerytabs">
              <Gallery imagesData={data}/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const images = graphql`
  query($galleryPath: String) {
    allFile(
      filter: { sourceInstanceName: { eq: $galleryPath } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
export default GalleryPage