import React, { Component, useState } from "react"

const WelcomeButtons = () => {
  const [isHoveringLeft, setIsHoveringLeft] = useState(false)
  const [isHoveringRight, setIsHoveringRight] = useState(false)
  return(
  <div className="no-gutters welcome-buttons" id="welcome">
    <div className={`welcome-photo-col --violet col-border-right ${isHoveringLeft ? 'apply-width' : 'remove-width'}`}
       onMouseEnter={() => setIsHoveringLeft(true)}
       onMouseLeave={() => setIsHoveringLeft(false)}>
      <div className="welcome-content-box">
        <h1 className="welcome-text">BBQ4.IT</h1>
        <div className="gradient"></div>
      </div>
    </div>
    <div className={`welcome-photo-col --orange ${isHoveringRight ? 'apply-width' : 'remove-width'} `}
       onMouseEnter={() => setIsHoveringRight(true)}
       onMouseLeave={() => setIsHoveringRight(false)}>
      <div className="welcome-content-box">
        <h1 className="welcome-text">Konferencja ATH</h1>
        <div className="gradient"></div>
      </div>
    </div>
  </div>
  )
}

export default WelcomeButtons