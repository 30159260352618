import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"

import "../../static/css/preloader.css"
import "../../static/css/bootstrap.min.css"
import "../../static/css/normalize.css"
import "../../static/css/font-awesome.css"
import "../../static/css/icomoon.css"
import "../../static/css/photoswipe.min.css"
import "../../static/css/owl.carousel.min.css"
import "../../static/css/default-skin.css"
import "../../static/css/animate.min.css"
import "../../static/css/transitions.css"
import "../../static/css/main.css"
import "../../static/css/color-green.css"
import "../../static/css/responsive.css"

export default function Template({ data }) {
  const post = data.markdownRemark
  const { title } = post.frontmatter
  return (
    <Layout>
      <Header onlyFixed />
      <div className="blog__header-bg" />
      <main id="tg-main" className="tg-main tg-haslayout">
        <div className="blog__wrapper">
          <h1 className="blog__title">{title}</h1>
          <div
            className="blog__content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export const postQuery = graphql`
  query MyQuery($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      frontmatter {
        title
        slug
        image
        description
        date
      }
      html
    }
  }
`
