import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const News = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                slug
                title
                image
                description
                date
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section id="tg-news" className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
              <div className="tg-sectionhead">
                <div className="tg-sectionheading">
                  <h2>Aktualności</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {data.allMarkdownRemark.edges.map(
                (
                  {
                    node: {
                      frontmatter: { name, image, title, slug, description },
                    },
                  },
                  index
                ) => {
                  const imageUrl = `images/mediaAboutUs/${image}.png`
                  return (
                    <div className="col-sm-6 col-md-4 media-card" key={index}>
                      <div className="thumbnail">
                        <img  src={imageUrl} alt={name} />
                        <div className="caption">
                          <h3 className="media-card__header">
                            <Link to={slug}>{title}</Link>
                          </h3>
                          <p>{description}</p>
                          <Link className="tg-btn -media-button " to={slug}>Czytaj więcej</Link>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      </section>
    )}
  />
)

export default News
