import React from "react"

const Counter = () => (
  <section className="tg-haslayout">
    <div className="container-fluid">
      <div className="row">
        <div className="tg-counterarea">
          <div className="tg-eventinfo">
            <figure className="tg-themepostimg">
              <img src="images/bb.jpg" alt="Poznaj idealne miejsce do do życia i pracy" />
              <figcaption>
                <time className="tg-timedate" dateTime="2021-09-06">6-12 września 2021</time>
                <h2>Idealne miejsce<span>do życia i pracy</span></h2>
              </figcaption>
            </figure>
          </div>
          <div id="tg-upcomingeventcounter" className="tg-upcomingeventcounter"></div>
        </div>
      </div>
    </div>
  </section>
)

export default Counter
