import React from 'react'

const Partners = () => (
  <section id="tg-partners" className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Partnerzy</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://www.pitneybowes.com" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/pitney_bowes.png" alt="Pitney Bowes" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://buddy.works/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/buddy.png" alt="Buddy" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://4experience.co/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/4experience.png" alt="4Experience" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.davinci-studio.com" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/davinczi.png" alt="Da Vinci Studio" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://innerweb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/innerweb.png" alt="InnerWeb" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://jantar.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/jantar.png" alt="Jantar" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.zf.com/poland/pl/home/home.html" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/zf.png" alt="ZFz" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.fablab24.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/fab-lab.png" alt="Fab Lab" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://cavatina.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/cavatina.png" alt="Cavatina" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://booksy.com/pl-pl" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/buksy.png" alt="Booksy" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://storware.eu/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/storware.png" alt="storware.eu" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.infomex.pl/strona-glowna" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/infomex.png" alt="infomex" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.eplan.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/eplan.png" alt="ePlan" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://soniqsoft.com/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/soniqSOFT.png" alt="SoniqSOFT"/></a></figure></li>
            <li className="tg-brand"><figure><a href="https://iteo.com/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/iteo_logo_color.png" alt="iteo"/></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.motionvfx.com/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/MotionVFX_logo.png" alt="motionvfx"/></a></figure></li>
        
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default Partners
