import React from 'react'

const MediaPatrons = () => (
  <section className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Patronat Medialny</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://www.radiobielsko.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/radio-bielsko.png" alt="Radio Bielsko" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.radio.katowice.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/radio-katowice.png" alt="Radio Katowice" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://beskidzka24.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/beskidzka.png" alt="Beskidzka24" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://kronika.beskidzka.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/kronika.png" alt="Kronika Beskidzka" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.bielsko.biala.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/bielsko-biala.png" alt="Miasto Bielsko Biała" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://justjoin.it/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/just.png" alt="Just Join It" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.mspgroup.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/msp.png" alt="MSP group" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.kurier-bb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/kurier-bb.png" alt="Kurier BB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://dotnetomaniak.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/dotnet-maniak.png" alt="Dotnet Maniak" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://mrdev.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/mr-dev.png" alt="Mr Dev" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://productdesignmagazine.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/pdm.png" alt="PDM" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://gamedevjs.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/game-dev.png" alt="GameDev.js" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://przeprogramowani.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/przeprogramowani.png" alt="przeprogramowani.pl" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://bbfan.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/bbfan.png" alt="bbfan" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://redakcjabb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/redakcja_bb.png" alt="redakcjaBB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://gazetabeskidzka.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/gazeta_beskidzka.png" alt="gazeta beskidzka" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default MediaPatrons
