import React from "react"

import "../../static/css/preloader.css";
import "../../static/css/bootstrap.min.css";
import "../../static/css/normalize.css";
import "../../static/css/font-awesome.css";
import "../../static/css/icomoon.css";
import "../../static/css/photoswipe.min.css";
import "../../static/css/owl.carousel.min.css";
import "../../static/css/default-skin.css";
import "../../static/css/animate.min.css";
import "../../static/css/transitions.css";
import "../../static/css/main.css";
import "../../static/css/color-green.css";
import "../../static/css/responsive.css";

const Regulamin = () => (
<div className="container">
  <header><h1>REGULAMIN KONFERENCJI TECHNICZNEJ „BBDAYS4.IT”</h1></header> <br /> <br />
  <section>
    <header>§ 1. POSTANOWIENIA OGÓLNE</header> <br />
    <div>
      <ol>
        <li>Niniejszy Regulamin określa warunki, tryb i zasady, na jakich odbywa się wydarzenie pod nazwą „ Konferencja techniczna w ramach festiwalu BBDAYS4.IT” (dalej „BBDAYS4.IT”, „Wydarzenie”, „Konferencja”) a także prawa i obowiązki Uczestników Konferencji.</li>
        <li>Organizatorem Konferencji jest Fundacja Centrum Nowych Technologii, z siedzibą w Bielsku-Białej, ul. Gałczyńskiego 31, 43-300 Bielsko-Biała, wpisana przez Sąd Rejonowy w Bielsku-Białej, Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000257174, posiadająca NIP: 9372490531. </li>
        <li>Konferencja rozpocznie się w dniu 11 września 2021 o godz. 9:00 i będzie trwać do godziny 16:00 i odbędzie się w Akademii Techniczno-Humanistycznej w Bielsku-Białej, ul. Willowa 2, 43-300 Bielsko-Biała.</li>
      </ol>
    </div>
  </section>
  <br />
  <section>
    <header>§ 2. ZASADY I WARUNKI UDZIAŁU</header> <br />
    <div>
      <ol>
        <li>Udział w Konferencji jest bezpłatny i dobrowolny.</li>
        <li>Uczestnikiem Konferencji może być każda osoba fizyczna posiadająca pełną zdolność do czynności prawnych. </li>
        <li>Warunkiem uczestnictwa w konferencji jest akceptacja niniejszego regulaminu oraz posiadanie ważnego biletu wstępu.</li>
        <li>Uczestnik zobowiązany jest przestrzegać aktualnych zasad i ograniczeń związanych z zagrożeniem epidemiologicznym, które są publikowane i na bieżąco aktualizowane na stronie internetowej https://www.gov.pl/web/koronawirus/aktualne-zasady-i-ograniczenia. W szczególności Uczestnik Konferencji powinien:
          <ul className="sublist">
            <li>Samodzielnie wyposażyć się w materiały ochrony osobistej (maseczka);</li>
            <li>Zakrywać nos i usta w przestrzeni budynku;</li>
            <li>Przestrzegać oznaczeń znajdujących się w budynku, w którym odbywać się będzie Konferencja;</li>
          </ul>
          Powyższe zalecenia mogą ulec zmianie w przypadku zmiany zaleceń publikowanych na stronie   <a href="https://www.gov.pl/web/koronawirus/aktualne-zasady-i-ograniczenia">https://www.gov.pl/web/koronawirus/aktualne-zasady-i-ograniczenia</a>
        </li>
        <li>Uczestnicy biorą̨ pełną odpowiedzialność́ za własność́ osobistą i są̨ zobligowani do jej pilnowania. Organizator nie ponosi odpowiedzialności za rzeczy Uczestników, które mogą zostać zgubione, zniszczone lub skradzione podczas Konferencji.</li>
        <li>Uczestnicy ponoszą pełną odpowiedzialność materialną za dokonane przez siebie zniszczenia na terenie obiektu, w którym będzie odbywać się Konferencja.</li>
        <li>Każdy Uczestnik oświadcza, że jeżeli w dniu Wydarzenia będzie poddany/a kwarantannie, nadzorowi sanitarnemu, ze względów bezpieczeństwa nie weźmie udziału w Imprezie.</li>
        <li>Każdy Uczestnik oświadcza, że jeżeli w ciągu ostatnich 14 dni przed udziałem w Wydarzeniu miałby bezpośredni kontakt z osobą z potwierdzonym zakażeniem COVID-19, osobą poddaną kwarantannie lub izolacji z powodu COVID-19, osobą, która miała widoczne objawy chorobowe, ze względów bezpieczeństwa nie weźmie udziału w Konferencji.</li>
        <li>Każdy Uczestnik oświadcza, że w ciągu ostatnich 14 dni przed udziałem w Konferencji nie miał objawy chorobowych COVID-19, w przeciwnym wypadku ze względów bezpieczeństwa nie weźmie udziału w Konferencji.</li>
        <li>Naruszenie przez Uczestników któregokolwiek z postanowień Regulaminu, naruszenie przepisów prawa lub ogólnie przyjętych zasad życia społecznego, w związku z uczestnictwem w Konferencji, upoważnia Organizatora do wykluczenia Uczestnika z udziału w wydarzeniu. </li>
        <li>Organizator zastrzega, że przebieg Konferencji będzie rejestrowany i może być transmitowany i nagrywany na nośniki w ramach audycji telewizyjnych, radiowych, internetowych i innych publicznych transmisji według dowolnej wybranej metody technicznej. Organizator zastrzega sobie prawo do odmówienia wzięcia udziału w konkurencji Uczestnikowi, który znajduje się w stanie po spożyciu alkoholu lub innych środków odurzających, zachowuje się agresywnie lub podejmuje inne działania mogące stanowić zagrożenie dla jego życia lub zdrowia albo zagrożenia dla życia lub zdrowia innych Uczestników i personelu obsługującego Wydarzenie.</li>
      </ol>
    </div>
  </section>
  <br />
  <section>
    <header>§ 3. REJESTRACJA UCZESTNICTWA</header> <br />
    <div>
      <ol>
        <li>Rejestracja do udziału w Konferencji odbywa się poprzez platformę internetową Evenea <a href="https://app.evenea.pl/event/bbdays4it-2021-konferencja-ath/">(https://app.evenea.pl/event/bbdays4it-2021-konferencja-ath/) </a> (dalej: Evenea)</li>
        <li>Organizator nie ponosi odpowiedzialności za podanie błędnych lub nieprawdziwych danych przez uczestnika podczas rejestracji na konferencję.</li>
        <li>Liczba miejsc na Konferencji jest limitowana.</li>
        <li>Maksymalna liczba Uczestników konferencji wynosi 400 osób. Organizator zastrzega sobie prawo do ograniczenia tej liczby w przypadku gdy zmianie ulegną zasady i ograniczenia wprowadzone w związku z Pandemią koronawirusa publikowane na stronie:  <a href="https://www.gov.pl/web/koronawirus/aktualne-zasady-i-ograniczenia">https://www.gov.pl/web/koronawirus/aktualne-zasady-i-ograniczenia</a> . W takim przypadku, obowiązywać będzie kolejność zgłoszeń</li>
        <li>Podczas rejestracji na konferencję na stronie Evenea, podanie informacji o posiadanym Unijnym Certyfikacie COVID jest dobrowolne i oznacza, iż uczestnik wyraża dobrowolną zgodę na weryfikację przez organizatora ważności Certyfikatu w dniu konferencji tj. 11 Września 2021. W przypadku odmowy okazania ważnego certyfikatu w dniu konferencji, Organizator zastrzega sobie prawo do udzielenia odmowy uczestnictwa w konferencji.
          <ul className="sublist">
            <li>Weryfikacja Certyfikatu odbędzie się za pomocą aplikacji Ministerstwa Zdrowia – Skaner Certyfikatów Covid.</li>
            <li>Udostępniając certyfikat do skanowania w dniu konferencji, Uczestnik wyraża dobrowolną zgodę na przetwarzanie danych osobowych zawartych w tym kodzie w niezbędnym zakresie.</li>
            <li>Po okazaniu certyfikatu i jego weryfikacji, organizator nie będzie dalej przechowywał, utrwalał ani przetwarzał w żaden sposób danych zawartych w certyfikacie.</li>
          </ul>
        </li>
        <li>Rejestracja Uczestników rozpocznie się w dn. 10 sierpnia 2021 r. i będzie trwać do  9 września 2021 roku. Organizator zastrzega sobie możliwość zamian ww. terminów.</li>
      </ol>
    </div>
  </section>
  <br />
  <section>
    <header>§ 4. DANE OSOBOWE UCZESTNIKÓW (RODO)</header> <br />
    <div>
      <ol>
        <li>Realizując obowiązek informacyjny wynikający z art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), potocznie zwanego RODO, przekazujemy niezbędne informacje dotyczące przetwarzania przez nas danych osobowych. </li>
        <li>Administratorem danych osobowych Uczestników jest Fundacja Centrum Nowych Technologii z siedzibą w Bielsku-Białej, ul. Gałczyńskiego 31, NIP: 937 249 05 31,</li>
        <li>Dane osobowe będą przetwarzane w celu przygotowania i realizacji Konferencji oraz weryfikacji rejestracji. Dane osobowe przetwarzane są w oparciu o zasady przetwarzania danych osobowych określone w art. 5 RODO.</li>
        <li>Dane osobowe przetwarzane są do osiągnięcia celu, dla którego zostały zebrane, przez czas, w którym przepisy nakazują Administratorowi przechowywać dane, przez czas konieczny do ustalenia, obrony i dochodzenia roszczeń oraz ze względu na każdy prawnie uzasadniony interes Administratora.</li>
        <li>Uczestnikom przysługuje prawo do: 
        <ul className="sublist">
            <li>dostępu do swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania, .</li>
            <li>przenoszenia swoich danych – prawo możliwe do realizowania w momencie, kiedy podstawą prawną przetwarzania jest zgoda osoby, której przetwarzanie dotyczy bądź realizacja umowy i przetwarzanie odbywa się w warunkach całkowitego automatyzowania,</li>
            <li>jeżeli dane osobowe Uczestnika były przetwarzane na podstawie udzielonej zgody, przysługuje mu prawo do jej cofnięcia w każdym czasie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. .</li>
          </ul>
        </li>
        <li>Uczestnicy mogą realizować swoje prawa, składając wniosek za pośrednictwem następującego kanału komunikacyjnego:
          <ul className="sublist">
            <li>wysyłając e-mail na adres: kontakt@bbdays4.it</li>
            <li>wysyłając pismo na adres korespondencyjny: Fundacja Centrum Nowych Technologii, ul. Gałczyńskiego 31, 43-300 Bielsko-Biała</li>
            <li>Uczestnikom przysługuje również prawo do złożenia skargi do Urzędu Ochrony Danych Osobowych.</li>
          </ul>
        </li>
        <li>Dane osobowe uczestników nie będą przez nas przetwarzane w sposób, który skutkowałby zautomatyzowanym podejmowaniem decyzji, w tym profilowaniem. Oznacza to, że nie wykorzystuje systemów informatycznych, które gromadziłyby informacje na temat Uczestników i jednocześnie samodzielnie, automatycznie, podejmowałyby decyzje, które mogłyby wywołać wobec Uczestników skutki prawne lub w podobny sposób istotnie na nich wpływać. </li>
        <li>Podanie danych osobowych jest dobrowolne, niemniej niepodanie danych uniemożliwi uczestnictwo w Wydarzeniu.</li>
        <li>Uczestnik konferencji wyraża zgodę na zarejestrowanie przez Organizatora jego wizerunku w trakcie trwania Konferencji w formie fotografii oraz materiałów audiowizualnych. Organizatorowi przysługuje nieograniczone w czasie i przestrzeni terytorialnej, nieodpłatne, prawo do wykorzystywania fotografii oraz materiałów audiowizualnych w celu związanym ze statutową działalnością Organizatora, w szczególności poprzez publikację ww. na własnych stronach internetowych, w mediach społecznościowych oraz własnych kanałach informacyjnych. Ponadto, materiały audiowizualne i fotografie mogą być publikowane na stronie internetowej i mediach społecznościowych festiwalu BBdays4.IT oraz stronie internetowej i mediach społecznościowych projektu InduCCI, program Interreg Central Europe.</li>
      </ol>
    </div>
  </section>
  <br />
  <section>
    <header>§ 5. POSTANOWIENIA KOŃCOWE</header> <br />
    <div>
      <ol>
        <li>Regulamin wchodzi w życie z dniem opublikowania go na stronie <a href="www.bbdays4.it">www.bbdays4.it</a>, <a href="www.evenea.pl">www.evenea.pl</a></li>
        <li>Organizator zastrzega sobie prawo do zmiany Regulaminu. Zmiany dokonane w Regulaminie wchodzą w życie w momencie opublikowania zmienionego Regulaminu na stronie internetowej <a href="www.bbdays4.it">www.bbdays4.it</a>, <a href="www.evenea.pl">www.evenea.pl.</a> </li>
        <li>Organizator zastrzega sobie prawo do odwołania lub przesunięcia na inny termin konferencji na skutek zajścia nadzwyczajnych okoliczności.</li>
        <li>W przypadku odwołania lub przesunięcia terminu Konferencji Organizator nie ponosi żadnej odpowiedzialności za szkody, jakie poniosły z tego tytułu osoby, które zgłosiły swój udział w wydarzeniu. </li>
        <li>Wszelkie spory mogące wyniknąć z tytułu uczestnictwa w Konferencji będą rozstrzygane przez sąd właściwy dla siedziby Organizatora, chyba że przepisy powszechnie obowiązującego prawa stanowią inaczej.</li>
        <li>Konferencja ma charakter zamknięty i nie stanowi imprezy masowej w rozumieniu Ustawy z dnia 23 marca 2009 roku o bezpieczeństwie imprez masowych (Dz.U. 2009 nr 62, poz. 504).</li>
        <li>W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają przepisy Kodeksu cywilnego.</li>
        <li>Naruszenie przez Uczestników któregokolwiek z postanowień Regulaminu, a w szczególności podanie nieprawdziwych danych osobowych, naruszenie przepisów prawa lub ogólnie przyjętych zasad życia społecznego, w związku z uczestnictwem w Konferencji, upoważnia Organizatora do wykluczenia Uczestnika z udziału w wydarzeniu.</li>
      </ol>
    </div>
  </section>

</div>
)

export default Regulamin